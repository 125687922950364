.content_select[data-v-34627b9f] {
  margin: 15px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-table[data-v-34627b9f] .cell {
  overflow: unset;
}
.none-border p[data-v-34627b9f] {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #b9b9b9;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-border p[data-v-34627b9f]:first-child {
  border-top: none;
}
.fold_icon[data-v-34627b9f] {
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 1;
  padding: 0 10px;
  border-radius: 14px;
  font-size: 11px !important;
  height: 20px;
  line-height: 20px;
}