
.none-border .cell {
  padding: 0;
}
.none-border .el-table__row td {
  padding: 0 !important;
}
.none-border .cell {
  padding: 0;
  text-align: center;
}
